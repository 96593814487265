<template>
  <div class="receive-address">
    <van-nav-bar :title="$t('title.userAddress')" left-arrow @click-left="goBack" :border="false">
      <template #right>
        <van-icon name="plus" size="20" @click="goToAdd"/>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="receive-address-list">
        <div class="seller-account-block" v-for="item in dataList" :key="item.id" @click="goToDetail(item)">
          <div class="seller-account-info">
            <div class="seller-account-name">{{item.address || '-'}}</div>
            <div class="seller-account-realname">{{$t('field.remark')}}: {{item.remark}}</div>
          </div>
          <van-icon class="arrow-icon" name="arrow" />
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryUserAccountList } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();

    const refreshing = ref(false);
    const cellThemeVars = {
      cellVerticalPadding: '13px',
    };
    return {
      goBack,
      refreshing,
      cellThemeVars
    };
  },

  data() {
    return {
      dataList: [],
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      queryUserAccountList().then(res => {
        this.dataList = res.data || [];
      });
    },
    refreshData() {
      this.fetchData();
    },
    goToDetail(item) {
      this.$AppData.setDataStore({
        qrCode: item.address,
        id: item.id,
        remark: item.remark,
      })
      this.$router.push('addUserAccount');
    },
    goToAdd() {
      this.$router.push('addUserAccount');
    },
    coldDown() {
      if (this.coldDownInterval) {
        clearInterval(this.coldDownInterval);
      }
      this.coldDownInterval = setInterval(() => {
        this.coldDownTime = this.coldDownTime - 1;
        if (this.coldDownTime ===0) {
          clearInterval(this.coldDownInterval);
        }
      }, 1000)
    },
  },
};
</script>

<style lang="less">
.receive-address {
  padding-bottom: 50px;
  .user-info-wrap {
    line-height: 26px;
    padding: 20px 10px;
    color: #fff;
    background: var(--van-primary-color);
    .user-header-title {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .seller-account-block {
    display: flex;
    align-items: center;
    line-height: 28px;
    padding: 12px 10px;
    margin: 10px 12px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 6px;
    .seller-account-info {
      flex: 1;
      .seller-account-name {
        font-size: 15px;
        font-weight: bold;
      }
      .seller-account-realname {
        color: #999;
      }
    }
    .arrow-icon {
      display: block;
      margin-left: 10px;
      line-height: 28px;
      font-size: var(--van-cell-icon-size);
      color: var(--van-cell-right-icon-color);
    }
  }
}
</style>
